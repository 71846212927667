body {

    font-family: 'Futura Lt BT', sans-serif, 'Futura Md BT';

}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
.ant-input-password-icon {
    color: #1890ff !important;
    text-decoration: underline;
    font-size: small;
    font-weight: bold;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
.text-blue {
    color: #0079D6;
}

.bg-blue {
    background-color: #0079D6;
}
.ant-layout-sider-zero-width-trigger {
    background-color: #0079D6;

}

.ant-layout-sider-children {
    overflow-y: auto;
}

#root {
    height: 100vh;
    overflow: hidden;
}


.hide-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


/*.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {*/
/*    background-color: unset;*/
/*    border-left: solid white thick;*/
/*    color: white;*/
/*}*/

/*.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {*/

/*    color: white;*/
/*}*/

/*.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {*/
/*    color: #bcc6d8;*/
/*}*/

/*.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover a {*/
/*    color: #bcc6d8;*/
/*}*/

/*.ant-menu-item a:hover {*/
/*    color: #bcc6d8;*/
/*}*/

.ant-menu-item a {
    color: gray;
}

.ant-menu-item-group-title {
    color: gray;
    font-weight: 600;
}

/*.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {*/
/*    color: white;*/
/*}*/

/*.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {*/
/*    color: white;*/
/*}*/
/*.ant-menu-sub.ant-menu-inline{*/
/*    !*background-color: #0b7acf;*!*/
/*}*/

/*.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {*/
/*    background-color: unset;*/
/*}*/

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

/*.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.batch-selected {*/
/*    background-color: unset;*/
/*    border-left: solid white thick;*/
/*    color: #282525;*/
/*}*/


.ant-drawer-close {


    right: unset;
}

.ant-drawer-body {
    padding: unset;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.ant-btn-success {
    color: #fff;
    background: #04c330;
    border-color: #04c330;
}
